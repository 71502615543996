import React, { FC } from "react";
import SEO from "../components/seo";
import { Layout } from "../components/layout";
import { Metrics } from "../components/metrics";
import { graphql } from "gatsby";
import { HtmlContent } from "../components/html-content";
import { NarrowContainer } from "../components/container";
import { Header } from "../components/header";
import { AboutUsTemplateQuery } from "../../graphql-types";
import { FluidObject } from "gatsby-image";

type Props = {
  data: AboutUsTemplateQuery;
};

const AboutPage: FC<Props> = (props) => {
  return (
    <Layout>
      <SEO title={props.data.wpPage?.title || ""} />

      <Header
        backgroundImage={
          props.data.wpPage?.featuredImage?.node?.localFile?.childImageSharp
            ?.fluid as FluidObject
        }
      >
        {props.data.wpPage?.title}
      </Header>

      <NarrowContainer style={{ marginTop: "3em", marginBottom: "3em" }}>
        <HtmlContent
          dangerouslySetInnerHTML={{ __html: props.data.wpPage?.content || "" }}
        />
      </NarrowContainer>

      <Metrics />
    </Layout>
  );
};

export default AboutPage;

export const query = graphql`
  query AboutUsTemplate($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      featuredImage {
        node {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
