import React from "react";
import { WideContainer } from "./container";
import styled from "styled-components";
import { baseHeading } from "./typography";
import { graphql, useStaticQuery } from "gatsby";
import { MetricsQuery } from "../../graphql-types";

export const Metrics: React.FC = () => {
  const data = useStaticQuery<MetricsQuery>(graphql`
    query Metrics {
      wp {
        globaal {
          globalCustomFields {
            metrics {
              label
              value
            }
          }
        }
      }
    }
  `);

  return (
    <div
      style={{
        backgroundColor: "#00a88e",
        color: "#fff",
      }}
    >
      <MetricsContainer>
        <MetricList>
          {data.wp?.globaal?.globalCustomFields?.metrics?.map((metric, i) => (
            <Metric key={i}>
              <MetricValue>{metric?.value}</MetricValue>
              <MetricLabel>{metric?.label}</MetricLabel>
            </Metric>
          ))}
        </MetricList>
      </MetricsContainer>
    </div>
  );
};

const MetricsContainer = styled(WideContainer)`
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 1rem 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 2rem 0;
  }
`;

const MetricList = styled.dl`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  margin: 0;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-color: #00a88e;
    border-style: solid;
    border-width: 1rem 4px;
  }
`;

const Metric = styled.div`
  position: relative;
  text-align: center;
  width: 50%;
  padding: 2rem;
  border: 1px solid #40b7a2;
  margin-top: -1px;
  margin-left: -1px;
  left: 1px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 25%;
  }
`;

const MetricValue = styled.dd`
  ${baseHeading}
  font-size: 40px;
  margin: 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 50px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: 60px;
  }
`;

const MetricLabel = styled.dt`
  text-transform: uppercase;
  font-weight: 700;
  font-size: 15px;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: 19px;
  }
`;
